import React, { FC, useEffect, useState } from 'react'
import Layout from '../components/layout/Layout'
import DashboardSwitch from '../components/dashboard/DashboardSwitch'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { AuthError, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'
import { loginRequest, msalInstance } from '../services/AuthConfig'
import { getCookie, portalUrl } from '../services/http-common'

const Dashboard: FC = () => {
  const { instance } = useMsal()
  const [first, setFirst] = useState(true)

  useEffect(() => {
    console.log('Loaded Dashboard')
    instance
      .ssoSilent(loginRequest)
      .then((response) => {
        console.log('Response from ssoSilent')
        if (response.account?.localAccountId !== instance.getActiveAccount()?.localAccountId) {
          instance.setActiveAccount(response.account)
        }
        setFirst(false)
      })
      .catch((err: any) => {
        if (err instanceof InteractionRequiredAuthError) {
          // window.location.replace(`${portalUrl}`)
          window.location.replace(`${portalUrl}/logout`)
        }
        console.log('Error for auth', err)
      })
  }, [instance])

  useEffect(() => {
    const interval = setInterval(() => {
      if (getCookie('refreshIdentity') === 'true') {
        document.cookie = `refreshIdentity=false; path=/; max-age=0; domain=.sistemiv.com`
        window.location.reload()
      }
    }, 1000)

    return () => clearInterval(interval)
  })
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Silent}
      authenticationRequest={{ ...loginRequest, account: instance.getActiveAccount() ?? undefined }}
      errorComponent={ErrorComponent}
    >
      {!first && (
        <Layout>
          <DashboardSwitch />
        </Layout>
      )}
    </MsalAuthenticationTemplate>
  )
}

export const ErrorComponent: FC<{ error: AuthError | null }> = ({ error }) => {
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      // window.location.replace(`${portalUrl}?app=${encodeURIComponent(window.location.href)}`)
      msalInstance.acquireTokenRedirect(loginRequest)
    }
  }, [error])
  return null
}

export default Dashboard
