import React, { FC } from 'react'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { Button } from '@sistemiv/s-components'
import EditInput from './EditInput'

export type ContactDetailsProps = {
  phone: string
  address: string
  city: string
  postCode: string
  country: string
  timeZone: string
  onChangePhoneNumber?: () => void
  onAddPhoneNumber?: () => void
  onRemovePhoneNumber?: () => void
  onEdit?(key: string, value: string): void
}

const ContactDetails: FC<ContactDetailsProps> = ({
  phone,
  address,
  city,
  postCode,
  onAddPhoneNumber,
  onChangePhoneNumber,
  onRemovePhoneNumber,
  onEdit,
}) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col py-4 max-w-xs gap-y-4'>
      <div className='space-y-1'>
        <label className='text-gray-500'>{t('phone')}</label>
        <div className='flex items-center py-2 gap-x-2'>
          {phone !== '' ? (
            <>
              <span>{phone}</span>
              <Button className='!p-0' onClick={() => onChangePhoneNumber && onChangePhoneNumber()}>
                <PencilIcon className='h-5 w-5 text-gray-500' />
              </Button>
              <Button className='!p-0' onClick={() => onRemovePhoneNumber && onRemovePhoneNumber()}>
                <TrashIcon className='h-5 w-5 text-gray-500' />
              </Button>
            </>
          ) : (
            <>
              <Button
                className='w-full whitespace-nowrap flex items-center gap-x-2 text-blue-500 !px-2 !py-0'
                onClick={() => onAddPhoneNumber && onAddPhoneNumber()}
              >
                <PlusIcon className='w-5 h-5' />
                {t('addPhone')}
              </Button>
            </>
          )}
        </div>
      </div>

      <div className='space-y-1'>
        <label className='text-gray-500'>{t('address')}</label>
        <EditInput value={address} onSave={(v) => onEdit && onEdit('address', v)} dataTestId='address' />
      </div>

      <div className='space-y-1'>
        <label className='text-gray-500'>{t('city')}</label>
        <EditInput value={city} onSave={(v) => onEdit && onEdit('city', v)} dataTestId='city' />
      </div>

      <div>
        <label className='text-gray-500'>{t('postCode')}</label>
        <EditInput value={postCode} onSave={(v) => onEdit && onEdit('post-code', v)} dataTestId='post-code' />
      </div>
    </div>
  )
}

export default ContactDetails
