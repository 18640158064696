import React, { FC } from 'react'
import { PencilIcon } from '@heroicons/react/24/solid'
import { Button } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import EditInput from './EditInput'

export type AboutMeProps = {
  firstName?: string
  lastName?: string
  jobTitle?: string
  company?: string
  email?: string
  isSocialAccount?: boolean
  status?: string
  onEdit?: (key: string, value: string) => void
  onChangeEmail?: () => void
  onChangePassword?: () => void
}
const AboutMe: FC<AboutMeProps> = ({
  firstName,
  lastName,
  jobTitle,
  company,
  email,
  //isSocialAccount,
  onEdit,
  onChangeEmail,
  //onChangePassword,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='flex flex-col py-4 max-w-xs gap-y-4'>
        <div className='space-y-1'>
          <label className='text-gray-500'>{t('firstName')}</label>
          <EditInput value={firstName} onSave={(v) => onEdit && onEdit('first-name', v)} dataTestId='first-name' />
        </div>

        <div className='space-y-1'>
          <label className='text-gray-500'>{t('lastName')}</label>
          <EditInput value={lastName} onSave={(v) => onEdit && onEdit('last-name', v)} dataTestId='last-name' />
        </div>

        <div className='space-y-1'>
          <label className='text-gray-500'>{t('jobTitle')}</label>
          <EditInput value={jobTitle} onSave={(v) => onEdit && onEdit('job-title', v)} dataTestId='job-title' />
        </div>

        <div className='space-y-1'>
          <label className='text-gray-500'>{t('company')}</label>
          <EditInput value={company} onSave={(v) => onEdit && onEdit('company', v)} dataTestId='company' />
        </div>

        <div className='space-y-1'>
          <label className='text-gray-500'>{t('email')}</label>
          <div className='flex items-center mb-4'>
            <span className='mr-2'>{email}</span>
            <Button className='!p-0' onClick={() => onChangeEmail && onChangeEmail()}>
              <PencilIcon className='h-5 w-5 text-gray-500' />
            </Button>
          </div>
        </div>

        {/* {!isSocialAccount && (
          <div className='flex items-start'>
            <button className='text-blue-500' onClick={() => onChangePassword && onChangePassword()}>
              {t('changePassword')}
            </button>
          </div>
        )} */}
      </div>
    </>
  )
}

export default AboutMe
