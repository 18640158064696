import React, { FC, PropsWithChildren } from 'react'
import SideNavMenu from './SideNavMenu'
import TopNav from './TopNav'

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='h-screen'>
      <TopNav />
      <div className='w-full h-[calc(100vh_-_4.185rem)] flex overflow-hidden divide-x separate-gray-300'>
        <SideNavMenu isSideMenuOpen={true} />
        <div className='grow'>{children}</div>
      </div>
    </div>
  )
}

export default Layout
