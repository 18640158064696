import { useMsal } from '@azure/msal-react'
import { Menu } from '@headlessui/react'
import { UserIcon, GlobeAltIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { AppsAndOrgsMenu, ImgWrapper, NavigationBar, SistemivLogoIcon } from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useUser } from '../../repositories/users/user.repository'
import { imageBase, portalUrl } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import { useMyOrganizations } from '../../repositories/organizations/organizations-my.respository'
import { useGetApplications } from '../../repositories/users/user-applications.repository'

export type OrgType = {
  id: string
  code: string
  image: string
  name: string
}

const TopNav: FC = () => {
  const navigate = useNavigate()
  const { instance } = useMsal()
  const { t, i18n } = useTranslation()
  const { data: user, isFetching: fetchingUser } = useUser({ userId: instance.getActiveAccount()?.localAccountId })
  const { data: organizationsPages } = useMyOrganizations({
    userId: instance.getActiveAccount()?.localAccountId,
  })
  const organizations: OrgType[] = useMemo(
    () =>
      organizationsPages?.pages
        .flat(Infinity)
        .map((r) => r.organizations)
        .flat()
        ?.map((o) => ({ id: o.organizationCode, code: o.organizationCode, image: o.icon, name: o.name })) ?? [],
    [organizationsPages],
  )
  const [org, setOrg] = useState<OrgType | null>(organizations.length > 0 ? organizations[0] : null)
  const { data: applications, isLoading: appsLoading } = useGetApplications(org ? org?.code : '')

  useEffect(() => {
    if (organizations.length > 0) {
      setOrg(organizations[0])
    }
  }, [organizations])

  const handleLogout = async () => {
    window.location.replace(`${portalUrl}/logout`)
  }

  const locales = [
    { value: 'en', title: 'English (en)' },
    { value: 'sr', title: 'Srpski (sr)' },
  ]

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('lng', lng)
  }

  return (
    <NavigationBar className='h-[4.185rem] border-b border-gray-300 flex items-center justify-between'>
      <div className='flex items-center gap-x-2 cursor-pointer' onClick={() => navigate('/')}>
        <SistemivLogoIcon className='text-[#2dcbfb] w-[40px] h-[30px]' />
        <p className='text-[#4b4f57] font-bold'>{t('myProfile').toUpperCase()}</p>
      </div>
      <div className='flex items-center gap-x-6'>
        {/* <NotificationsMenuItem
          unreadNotifications={3}
          labels={{
            notifications: 'Notifications',
            show_unread: 'Show unread',
            mark_all_as_read: 'Mark all as read',
          }}
        /> */}
        <Menu as='div' className='relative'>
          <Menu.Button
            name='nav-language'
            className='inline-flex w-full justify-center items-center gap-x-2 px-1 py-2 rounded-md hover:bg-sky-50 active:bg-sky-100'
          >
            <GlobeAltIcon className='h-6 w-6' />
            <div data-testid='current-language'>{i18n.resolvedLanguage}</div>
          </Menu.Button>
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            {locales.map((locale) => (
              <Menu.Item key={`language-${locale.value}`} data-testid='nav-language'>
                {() => (
                  <div
                    onClick={() => handleChangeLng(locale.value)}
                    className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                    data-testid='nav-language'
                  >
                    {locale.title}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
        {organizations.length > 0 && org && (
          <AppsAndOrgsMenu
            organizations={organizations}
            applications={applications}
            appsLoading={appsLoading}
            org={org}
            setOrg={setOrg}
          />
        )}
        <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <ImgWrapper
              className='w-8 h-8 rounded-full border-sky-500 border'
              src={`${imageBase}/${user?.data.id}/76?p=${fetchingUser ? '' : new Date().getMilliseconds()}`}
              onErrorComponent={
                <div className='bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center text-lg leading-[18px] border-sky-500 border'>
                  {user ? (
                    user?.data?.firstName?.charAt(0)?.toUpperCase()
                  ) : (
                    <UserIcon className='w-full h-full text-white' />
                  )}
                </div>
              }
            />
          </Menu.Button>
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            <Menu.Item key='portal'>
              <div
                onClick={() =>
                  window.location.replace(
                    `https://portal${
                      process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                    }.sistemiv.com`,
                  )
                }
                className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                data-testid='nav-app'
              >
                <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                  <span>App Portal</span>
                  <ArrowRightIcon className='w-5 h-5 ml-2' />
                </div>
              </div>
            </Menu.Item>
            <Menu.Item as='div' onClick={() => handleLogout()}>
              <div className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'>{t('logout')}</div>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </NavigationBar>
  )
}

export default TopNav
